import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { SimpleTour } from 'src/app/helper/simple-tour';
import { PageTour } from 'src/app/model/page-tour.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PageTourService implements OnDestroy {

  tour = new ReplaySubject<PageTour | null>(1);
  private simpleTour?: SimpleTour;
  private subscription?: Subscription;

  constructor(private http: HttpClient) {}

  setTour(tourKey: string | null): void {
    if (tourKey) {
      this.http.get<PageTour | null>(environment.apiUrl + 'tour/' + tourKey).subscribe(tour => {
        this.tour.next(tour?.steps?.length ? tour : null);
        //check and start tour if push
        if (!tour?.viewedFlag && tour?.pushFlag) {
          this.showTour(tour);
        }
      });
      return;
    }
    this.tour.next(null);
  }

  showTour(pageTour: PageTour | null | undefined): void {
    if (!pageTour || pageTour?.pageTourStatCd !== 'A') {
      return;
    }
    setTimeout(() => {
      this.simpleTour = new SimpleTour({}, pageTour);
      this.simpleTour.tour?.start();
    });
  }

  ngOnDestroy(): void {
    this.simpleTour?.tour?.cancel();
    this.subscription?.unsubscribe();
  }

}
