import Step from 'shepherd.js/src/types/step';
import Shepherd from 'shepherd.js';
import { offset, autoPlacement } from '@floating-ui/core';
import Tour from 'shepherd.js/src/types/tour';
import { PageTour } from 'src/app/model/page-tour.model';
import { left } from '@popperjs/core';
import { EventEmitter } from '@angular/core';
import StepOptionsButton = Step.StepOptionsButton;
import TourOptions = Tour.TourOptions;

export class SimpleTour {

  onTourCompleted = new EventEmitter<boolean>();
  tour?: Shepherd.Tour;

  btnExit: StepOptionsButton = {
    classes: 'btn btn-outline-secondary me-auto',
    text: 'Skip Tour',
    action: () => {
      this.tour?.cancel();
    }
  };
  btnEnd: StepOptionsButton = {
    classes: 'btn btn-secondary',
    text: 'Got It!',
    action: () => {
      this.tour?.complete();
      this.onTourCompleted.emit(true);
    }
  };
  btnNext: StepOptionsButton = {
    classes: 'btn btn-primary',
    text: 'Next',
    action: () => {
      this.tour?.next();
    }
  };
  btnPrev: StepOptionsButton = {
    classes: 'btn btn-primary',
    text: 'Previous',
    action: () => {
      return this.tour?.back();
    }
  };

  constructor(options: TourOptions, pageTour: PageTour) {
    this.tour = new Shepherd.Tour({
      ...options,
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: {
          block: 'nearest'
        },
        modalOverlayOpeningPadding: 10,
        canClickTarget: false,
        classes: 'shepherd-tour-step',
        floatingUIOptions: {
          middleware: [autoPlacement(), offset(20)]
        }
      }
    });
    pageTour.steps.sort((a, b) => a.seq - b.seq);

    const tourSteps = pageTour.steps.map(({seq, stepTitlText, stepDescText, stepModalSizeCd, htmlElemId}, index) => {
      let stepButtons: StepOptionsButton[] | undefined;
      if (index === 0) {
        stepButtons = [this.btnExit, this.btnNext];
      } else if (index === pageTour.steps.length - 1) {
        stepButtons = [this.btnPrev, this.btnEnd];
      } else {
        stepButtons = [this.btnExit, this.btnPrev, this.btnNext];
      }

      let classes = '';
      switch (stepModalSizeCd) {
        case 'S':
          classes = 'w-25';
          break;
        case 'M':
          classes = 'w-50';
          break;
        case 'L':
          classes = 'w-75';
          break;
      }

      return {
        id: 'tour-step-' + index,
        title: stepTitlText,
        text: stepDescText,
        classes: classes,
        attachTo: {
          element: htmlElemId,
          on: left
        },
        buttons: stepButtons
      };
    });

    this.tour?.addSteps(tourSteps);
  }
}
