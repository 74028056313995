import { Injectable } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, map, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {

  routerEvents = new ReplaySubject<NavigationEnd>();
  pageRefCd = new ReplaySubject<string>();

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
      map((event) => event instanceof Scroll ? event.routerEvent as NavigationEnd : event as NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.routerEvents.next(event);
    });
  }

}
